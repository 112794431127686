<template>
  <module
    ref="module"
    id="params"
    titleIcon="nc-icon nc-settings-gear-65"
    :title="$t('paramschanges')"
    :use-default-list="false"
    >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

      <!-- Modal for Confirm Removal -->
      <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('paramschanges') }} {{ $t('completedtxt') }}?</h4>

        <div class="row">
          <div class="col-12">
            {{ $t('confirmtaskdone') }}
          </div>
        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button @click.prevent="confirmedTaskDone" link>{{ $t('yestxt') }}</p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="danger" v-on:click="modalconfirm = false" link>{{ $t('notxt') }}</p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->

      <!-- Modal for Parameters Removal -->
      <modal :show.sync="modalparams" headerClasses="justify-content-center" modalClasses="modal-lg">
        <h4 slot="header" class="title title-up">{{ $t('paramschanges') }} {{ $t('oftxt') }} {{ accountnum }}</h4>

        <div class="col-12 text-center" v-if="alert_me_modal">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">
          <div :class="(accountsettings.allocationMethod == 'lotMoneyRatio') ? 'col-sm-4' : 'col-sm-6'">
            <h6>{{ $t('allocation') }}</h6> {{ allocmeths[accountsettings.allocationMethod] }}
          </div>
          <div class="col-sm-6" v-if="((accountsettings.lotMoneyRatioX === undefined && accountsettings.lotMoneyRatioY === undefined) || (accountsettings.lotMoneyRatioY === null && accountsettings.lotMoneyRatioX === null))">
            <h6>{{ allocmeths[accountsettings.allocationMethod] }}</h6> {{ (accountsettings[accountsettings.allocationMethod]) ? accountsettings[accountsettings.allocationMethod] : $t('notavailable') }}
          </div>
          <div class="col-sm-4" v-if="accountsettings.lotMoneyRatioX">
            <h6>{{ $t('helper_lotmoneyratiox') }}</h6> {{ (accountsettings.lotMoneyRatioX) ? accountsettings.lotMoneyRatioX : $t('notavailable') }}
          </div>
          <div class="col-sm-4" v-if="accountsettings.lotMoneyRatioY">
            <h6>{{ $t('helper_lotmoneyratioy') }}</h6> {{ (accountsettings.lotMoneyRatioY) ? accountsettings.lotMoneyRatioY : $t('notavailable') }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('copyOpenTradesTxt') }}</h6> {{ (accountsettings.copyOpenTrades) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('copytradessincetxt') }}</h6> {{ (accountsettings.copyTradesSince) ? accountsettings.copyTradesSince : ( (accountsettings.copyOpenTrades) ? $t('alwaystxt') : $t('notavailable')) }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('blockSignalsWithPriceDeviationTxt') }}</h6> {{ (accountsettings.blockSignalsWithPriceDeviation) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('maxpiptxt') }}</h6> {{ (accountsettings.maxPip) ? accountsettings.maxPip : $t('notavailable') }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings" :class="(accountsettings.onlyTheseChanged || accountsettings.allButTheseChanged) ? 'bg-danger p-3' : ''">
          <div class="col-sm-6">
            <h6>{{ $t('assetspairstoreceivesignalstxt') }}</h6> {{ assettorcvsignals[accountsettings.assetsPairsToReceiveSignals] }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('assetspairstoreceivesignalstxt') }} {{ $t('infotxt') }}</h6>
            {{ accountsettings[accountsettings.assetsPairsToReceiveSignals] }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('tradesidetxt') }}</h6> {{ tradesidereplicated[accountsettings.tradeSide] }}
          </div>
          <div class="col-sm-6">
            <!--<h6>{{ $t('tradesidetxt') }} {{ $t('infotxt') }}</h6> {{ (accountsettings[accountsettings.tradeSide]) ? accountsettings[accountsettings.tradeSide] : $t('notavailable') }}-->
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('receivenewtradesignalstxt') }}</h6> {{ (accountsettings.receiveNewTradeSignals) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('receiveclosetradesignalstxt') }}</h6> {{ (accountsettings.receiveCloseTradeSignals) ? $t('yestxt') : $t('notxt') }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('maxaccountmarginusedtxt') }}</h6> {{ (accountsettings.maxAccountMarginUsed) ? (accountsettings.maxAccountMarginUsed) : $t('notavailable') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('maxlotsizetxt') }}</h6> {{ (accountsettings.maxLotSize) ? (accountsettings.maxLotSize) : $t('notavailable') }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-4">
            <h6>{{ $t('closealltradesatoveralllosstxt') }}</h6> {{ (accountsettings.closeAllTradesAtOverallLoss) ? $t('yestxt') : $t('notxt') }}
          </div>
          <div class="col-sm-4">
            <h6>{{ $t('losspercenttocloseprofittxt') }}</h6> {{ (accountsettings.lossPercentToCloseProfit) ? (accountsettings.lossPercentToCloseProfit) : $t('notavailable') }}
          </div>
          <div class="col-sm-4">
            <h6>{{ $t('lossmoneytocloseprofittxt') }}</h6> {{ (accountsettings.lossMoneyToCloseProfit) ? (accountsettings.lossMoneyToCloseProfit) : $t('notavailable') }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('maxnumberopenpositionstxt') }}</h6> {{ (accountsettings.maxNumberOpenPositions) ? (accountsettings.maxNumberOpenPositions) : $t('notavailable') }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('nonewtradesdrawdownabovetxt') }}</h6> {{ (accountsettings.noNewTradesDrawdownAbove) ? (accountsettings.noNewTradesDrawdownAbove) : $t('notavailable') }}
          </div>
        </div>

        <div class="row" v-if="accountsettings">&nbsp;</div>

        <div class="row" v-if="accountsettings">
          <div class="col-sm-6">
            <h6>{{ $t('helper_tradedassetsmatching') }}</h6>
            {{ tradedAssetsMatching }}
          </div>
          <div class="col-sm-6">
            <h6>{{ $t('helper_assetsuffix') }}</h6>
            {{ assetSuffix }}
          </div>
        </div>

        <template slot="footer">
          <div class="left-side">
            <p-button @click.prevent="done" link>{{ $t('finish') }}</p-button>
          </div>
          <div class="divider"></div>
          <div class="right-side">
            <p-button type="danger" v-on:click="modalparams = false" link>{{ $t('cancel') }}</p-button>
          </div>
        </template>
      </modal>
      <!-- End Modal -->
      <div class="col-12 text-center" v-if="alert_me">
        <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
          {{ alert_msg }}
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">

          <div class="col-sm-2">
            <label> {{ sortbyTxt }} </label>
            <el-select v-model="sortby.column" :placeholder="sortbyTxt"
              v-on:change="sortByCol">
              <el-option class="select-default"
                v-for="item in sortby.columns"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ perpageTxt }} </label>
            <el-select
              v-model="pagination.perPage" :placeholder="perpageTxt">
              <el-option class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ sortbyOrder }} </label>
            <el-select
              v-model="sortby.order" :placeholder="sortbyOrder"
              v-on:change="sortByCol">
              <el-option class="select-default"
                v-for="item in sortby.orders"
                :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>


          <div class="col-sm-6">
            <div class="pull-right">
              <label> {{ searchtxt }} </label>
              <fg-input class="input-sm"
                        v-model="searchQuery"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped"
                      :data="queriedData"
                      border
                      style="width: 100%">
              <el-table-column :label="actionlbl" class="success" min-width="250" width="250">
                <div slot-scope="scope" class="text-center">
                  <div class="btn-group">
                    <el-tooltip :content="`${$t('task_completed')}`" placement="top">
                      <p-button type="primary" link v-on:click="changes(`${scope.row.id}`, `${scope.row.account}`)">
                        <i class="fa fa-cogs" style="font-size: x-large;"></i>
                      </p-button>
                    </el-tooltip>
                  </div>
                </div>
              </el-table-column>

              <el-table-column
                :label="`${this.$t('account')}`">
                <template slot-scope="scope">
                  <span v-on:click="copyit(scope.row.account)" class="clickable">
                    {{ scope.row.account }} ({{ scope.row.platform.toUpperCase() }})
                  </span>
                </template>
              </el-table-column>

              <el-table-column v-for="column in tableColumns"
                               :key="column.label"
                               :min-width="column.minWidth"
                               :prop="column.prop"
                               :formatter="column.formatter"
                               :label="column.label"
                               :class="(column.acc_type == 'master') ? 'primary' : 'info'">
              </el-table-column>

            </el-table>

            <input id="clipit" type="text" v-model="clipboard" style="display: none">
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right"
                          v-model="pagination.currentPage"
                          :per-page="pagination.perPage"
                          :total="pagination.total">
            </p-pagination>
          </div>
        </div>
      </div>
    </div>
    </div>
  </module>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import {Button, Modal} from 'src/components/UIComponents'
  import {Tooltip} from 'element-ui'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)
  Vue.use(require('vue-moment'))

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  let bills = [];
  let timeoutcount = 1500;

  export default {
    components: {
      PPagination, [Button.name]: Button, Modal,
      [Tooltip.name]: Tooltip
    },
    computed: {
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              //let rowValue = row[key].toString()
              let searchRegex = new RegExp(this.searchQuery, 'gi');
              //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
              if (row[key].toString().match(searchRegex) !== null) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      }
    },
    data () {
      return {
        perpageTxt: this.$t('perpage'),
        sortbyTxt: this.$t('sortby'),
        sortbyOrder: this.$t('sortbyorder'),
        searchtxt: this.$t('searchtxt'),
        colSort: 'entry_dt', inprogress: true, actionlbl: this.$t('action'), alert_me: false, alert_msg: null,
        alert_class: 'text-danger', modalparams: false, accountnum: null, accountsettings: null, tradedAssetsMatching: null,
        alert_me_modal: false, helper_tradedassets: this.$t('assetmatching'), helper_assetsuffix: this.$t('helper_assetsuffix'),
        inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('paramschanges') +". "+ this.$t('pleasewait'),
        assetSuffix: null,
        allocmeths: {
          'eqRatio': 'Equity Ratio', 'balRatio': 'Balance Ratio', 'lotMultiplier': 'Lot Multiplier',
          'lotMoneyRatio': 'Lot Money Ratio', 'fixedLots': 'Fixed Lots'
        },
        assettorcvsignals: {
          'all': 'All', 'onlyTheseAssetsPairsReceiveSignal': 'Only These',
          'allButTheseAssetsPairsReceiveSignal': 'All but These'
        },
        tradesidereplicated: {
          'all': 'All', 'onlyBuy': 'Only Buy', 'onlySell': 'Only Sell'
        },
        sortby: {
          column: 'location',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'userid',
              label: this.$t('userid')
            },
            {
              prop: 'first_name',
              label: this.$t('firstname')
            },
            {
              prop: 'last_name',
              label: this.$t('lastname')
            },
            {
              prop: 'strat_name',
              label: this.$t('strategytxt')
            },
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'location',
              label: this.$t('mattxt')
            },
            {
              prop: 'entry_dt',
              label: this.$t('date')
            }
          ]
        },
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: ['userid', 'first_name', 'last_name', 'strat_name', 'account', 'location'],
        tableColumns: [
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          },
          {
            prop: 'location',
            label: this.$t('mattxt')
          },
          {
            prop: 'entry_dt',
            label: this.$t('date')
          }
        ],
        tableData: [], modalconfirm: false, passid: null, did: null,
        clipboard: ''
      }
    },
    methods: {
      copyit(value) {
        let cb = document.getElementById("clipit");
        cb.style.display = 'block';
        cb.value = value;
        cb.select();
        document.execCommand('copy');
        cb.style.display = 'none';
        this.$toast.success(`${value} Copied!`)
      },
      closeModalAlert() {
        this.modalconfirm = false;
      },
      closeModalParams() {
        this.modalparams = false;
        this.accountnum = null;
        this.accountsettings = null;
        //this.did = null;
      },
      completedTask(resp) {
        this.closeModalAlert();
        this.$toast.success(this.$t(resp.msg))
        this.did = null;
        // Reload and close
        this.$refs.module.refreshList();
        this.$getParamsChanges().then(this.loadIt, this.failop);
        setTimeout(this.resetAlert, timeoutcount);
      },
      confirmedTaskDone() {
        this.$paramsChangesDone(this.did, user_data.email).then(this.completedTask, this.failop);
      },
      changes(id, account) {
        let cidx = this.tableData.findIndex(t => parseInt(t.id) === parseInt(id));
        if (cidx >= 0) {
          this.did = id;
          this.accountnum = account;
          this.accountsettings = this.tableData[cidx].settings;
          this.accountsettings['allButTheseChanged'] = this.tableData[cidx].allButTheseChanged;
          this.accountsettings['onlyTheseChanged'] = this.tableData[cidx].onlyTheseChanged;
          this.tradedAssetsMatching = this.tableData[cidx].tradedAssetsMatching;
          this.assetSuffix = this.tableData[cidx].assetSuffix;
          this.modalparams = true;
        }
      },
      done() {
        if (this.did) {
          this.closeModalParams();
        }
        this.modalconfirm = true;
      },
      resetAlert() {
        this.alert_me = false;
        this.alert_msg = null;
        this.alert_class = 'text-danger';
      },
      loadIt(resp) {
        this.tableData = [];
        for(var i=0;i < resp.data.length;i++) {
          let s = resp.data[i];
          s['showingpass'] = false;
          s['public'] = this.$t('notxt');
          if (s.discoverable) {
            s['price'] = (parseFloat(s['price']) * 100).toString() + "%";
            s['public'] = this.$t('yestxt');
          }
          this.tableData.push(s);
        }
        this.sortByCol();
        this.inprogress = false;
      },
      showpass(id, pwd) {
        const el = document.createElement('textarea');
        el.value = pwd;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (this.passid !== null && this.passid !== id) {
          const prid = this.tableData.findIndex(t => t.id === this.passid);
          this.tableData[prid].showingpass = false;
        }

        const rid = this.tableData.findIndex(t => t['id'] == id);
        if (rid >= 0) {
          this.passid = rid;
          this.tableData[rid].showingpass = true;
          const removeShowingPass = id => {
            this.tableData[rid].showingpass = false;
            this.passid = null;
          }
          setTimeout(removeShowingPass, timeoutcount);
        }
      },
      sortItNum (a, b) {
        if (this.sortby.order == 'asc') {
          return a[this.colSort] - b[this.colSort];
        }
        return b[this.colSort] - a[this.colSort];
      },
      sortIt (a, b) {
        if (a[this.colSort] == b[this.colSort]) {
          return 0;
        }
        if (a[this.colSort] < b[this.colSort]) {
          return this.sortby.order == 'asc' ? -1 : 1;
        } else {
          return this.sortby.order == 'asc' ? 1 : -1;
        }
      },
      sortByCol() {
        this.colSort = this.sortby.column;
        this.tableData.sort(this.sortIt);
      },
      failop (error) {
        if (error.data && error.data.msg) {
          error = error.data.msg;
        }
        this.$toast.error(error);
      }
    },
    mounted () {
      this.$getParamsChanges().then(this.loadIt, this.failop);
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
  }
</script>
<style>
</style>
